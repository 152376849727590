import React from 'react'
import Content from '../components/content'
import FetchPhotos from '../components/fetch-photos'
import titleImg from '../images/bg8.jpg'

const Photos = () => {
  return (
    <Content title='Our practice' titleImg={titleImg} tagline='Where patient care starts'>
      <FetchPhotos/>
    </Content>
  )
}

export default Photos