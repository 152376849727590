import React, { useEffect, useState } from 'react'
import Gallery from './gallery'
import axios from "axios"

const FetchPhotos = () => {
  const [images, setImages] = useState([])

  useEffect(() => {
    const fetchFileList = async () => {
      const url = process.env.GATSBY_IMAGEKIT_LIST_FILES
      try {
        const config = {
          method: 'post',
          headers: {"Content-Type": "application/json"},
          url: url,
          data: {
            "path" : "AspireMC"
          }          
        }      
        const result = await axios(config)
        const imgList = result.data
        const sorted = imgList.sort((a, b) => {
        const aTime = new Date(a.createdAt)
        const bTime = new Date(b.createdAt)
        if (aTime < bTime)
          return 1
        else
          return -1
        })
        setImages(sorted.map(item => item.url))
      } catch (err) {
        console.log(`imagekit.io file list error @ url ${url}`, err)
      }
    }

    fetchFileList()
  }, []) 

  return (
    <Gallery images={images}/>
  )
}

export default FetchPhotos